<template>
  <div class="inline">
    <a v-if="isExternal" :href="to" class="underline hover:text-green-700" target="_blank">
      <slot />
    </a>
    <Link v-else :href="to" class="underline hover:text-green-700">
      <slot />
    </Link>
  </div>
</template>

<script setup lang="ts">
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';
const props = defineProps<{
  to: string;
}>();

const isExternal = computed(() => props.to.startsWith(import.meta.env.VITE_APP_URL) === false);
</script>

<style scoped></style>
